<template>
  <base-layout>
    <RentPlanAddEdit
      :es-id="addEdit.esId"
      :stepper-mode="addEdit.stepperMode"
      :stepper-step="addEdit.stepperStep"
      :primary-key="addEdit.primaryKey"
      :raw-data="addEdit.rawData"
      :vehicleModels="addEdit.vehicleModels"
      :rentPackages="addEdit.rentPackages"
      :rentalEnabled="hasRentalActive"
      :busy="addEdit.busy"
      @refresh="$store.dispatch('fsTable/fetchData')"
    />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitlePlus
            :title="$t('components.rentPricingPlanManagement.headline')"
            @plus="add"
            :hide-plus="$acl.canNotCreate('Rent Pricing Plan')"
          />
        </div>
      </div>
      <div
        v-if="$acl.canNotView('Rent Pricing Plan')"
        class="py-5 font-bold text-center text-gray-600"
      >
        {{ $t('components.acl.doNotHavePermission') }}
      </div>

      <FSTable
        v-if="$acl.canView('Rent Pricing Plan') && hasRentalActive"
        :fst-id="`rentPricingIndex`"
        :headers="tableHeaders"
        :endpoint="indexDataEndpoint"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <FSTableRow
            v-for="(item, itemIndex) in data"
            :key="itemIndex"
            text-fallback-always
          >
            <FSTableRowItem :text="item.id" :truncate="-5" />

            <FSTableRowItem>
              <div
                @click="onOpenES({ primaryKey: item.id })"
                class="font-semibold text-blue-700 cursor-pointer"
              >
                {{ item.name }}
              </div>
            </FSTableRowItem>

            <FSTableRowItem>
              <span
                v-if="item.tax_plan"
                v-tooltip="`${parseFloat(item.tax_plan.percentage)}%`"
              >
                {{ item.tax_plan.name }}
              </span>
            </FSTableRowItem>

            <FSTableRowItem>
              {{ item.fleet ? item.fleet.name : '--' }}
            </FSTableRowItem>
            <FSTableRowItem>
              {{
                item.organization_vehicle_models
                  ? item.organization_vehicle_models
                      .map((item) => item.title)
                      .join(',')
                  : '--'
              }}
            </FSTableRowItem>
            <FSTableRowItem>
              {{ getCurrencySymbol(item) }}
              {{ parseFloat(item.vehicle_rental_per_unit_fees) }}/{{
                item.vehicle_rental_fee_unit_display_text
              }}
            </FSTableRowItem>
            <FSTableRowItem>
              <XStatus
                :variant="item.is_active ? 'green' : 'gray'"
                :text="item.is_active ? 'Active' : 'Inactive'"
                size="small"
              />
            </FSTableRowItem>
            <FSTableRowItem>
              <div class="flex items-center">
                <oto-edit-icon @click="onOpenES({ primaryKey: item.id })" />
              </div>
            </FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import XStatus from '@/components/badge/XStatus'

import { RentPlanConfig } from '@/config/RentPlanConfig'

import RentPlanAddEdit from '@/views/pricing/add-edit-rent-plan/RentPlanAddEdit.vue'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import { useEndpoints } from '@/composables'
export default {
  name: 'RentPlans',

  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    OtoEditIcon,
    RentPlanAddEdit,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    XStatus,
  },
  props: {
    filterItems: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    hasRentalActive() {
      return this.$org?.subscription?.rental_enabled
    },
  },
  data() {
    return {
      symbol: '$',
      indexDataEndpoint: RentPlanConfig.api.index,
      vehicleTypes: [],
      tableHeaders: [
        {
          text: `${this.$t(
            'components.rentPricingPlanManagement.table.columns.id'
          )}`,
          width: '7%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.rentPricingPlanManagement.table.columns.name'
          )}`,
          width: '12%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.rentPricingPlanManagement.table.columns.taxPlan'
          )}`,
          width: '7%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.rentPricingPlanManagement.table.columns.assignedFleet'
          )}`,
          width: '12%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.rentPricingPlanManagement.table.columns.vehicleModels'
          )}`,
          width: '12%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.rentPricingPlanManagement.table.columns.rentUnitFee'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.rentPricingPlanManagement.table.columns.status'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.rentPricingPlanManagement.table.columns.actions'
          )}`,
          width: '5%',
          sort: null,
        },
      ],
      addEdit: {
        // EdgeStack component
        esId: 'rent-pricing-plan-add-edit',

        // XStepper component
        stepperMode: 'strict',
        stepperStep: 1,

        // Data
        busy: true,
        rawData: null,
        vehicleModels: null,
        rentPackages: null,
        primaryKey: null,
      },
    }
  },
  async created() {
    const vehicleTypeMap = {
      'SCOOTER': {
        text: 'Scooter',
        value: 'P',
      },
      'SCOOTER PRO': {
        text: 'Scooter Pro',
        value: 'PP',
      },
      'EBIKE': {
        text: 'E-Bike',
        value: 'E',
      },
      'BIKE': {
        text: 'Bike',
        value: 'S',
      },
      'COCO': {
        text: 'SuperCoco',
        value: 'C',
      },
      'KENOTA': {
        text: 'Kenota',
        value: 'K',
      },
    }
    const orgVehicleTypes = this?.$org?.vehicle_type || []
    orgVehicleTypes.forEach((t) => this.vehicleTypes.push(vehicleTypeMap[t]))
  },
  async mounted() {
    this.symbol = this.organizationInfo.default_currency
      ? this.organizationInfo.default_currency.symbol
      : '$'
  },
  methods: {
    getSerializeRentData(pkData) {
      if (pkData.length > 0) {
        const modifiedPkData = pkData.map((item) => {
          return {
            id: item.id,
            period: item.period,
            cost: item.cost,
            addDescriptionUI: item.description ? true : false,
            description: item.description,
          }
        })
        return modifiedPkData
      } else {
        return pkData
      }
    },
    add: function() {
      this.onOpenES({ primaryKey: null })
    },

    // view: function(item) {
    //   console.log(item)
    //   EventBus.$emit(BillingPlanConfig.events.viewingData, item)
    //   this.$modal.show(BillingPlanConfig.events.viewingData)
    // },
    async onOpenES({ primaryKey }) {
      if (typeof primaryKey === 'string') {
        this.addEdit.busy = true
        this.$edgeStack.open(this.addEdit.esId)

        const rReq = this.$http.get(RentPlanConfig.api.details(primaryKey))
        const rentPackageReq = this.$http.get(
          useEndpoints.rentPricingPlan.rentPackages(primaryKey)
        )
        const requests = [rReq, rentPackageReq]

        await this.$http
          .all(requests)
          .then(
            this.$http.spread((...responses) => {
              let data = {}
              const [rRes, rentPackagesRes] = responses
              console.log(rRes.data, 'rRes')

              this.$http
                .get(
                  `${RentPlanConfig.api.vehicleModelChoices(
                    rRes.data.fleet.id
                  )}?to_update_rental_pricing=${rRes.data.id}`
                )
                .then((res) => {
                  data = { ...rRes.data }

                  this.addEdit.stepperMode = 'free'

                  this.addEdit.busy = false
                  this.addEdit.rawData = data
                  this.addEdit.rentPackages = rentPackagesRes.data.map(
                    (item) => {
                      return {
                        text: item.text,
                        value: item.value,
                        data: this.getSerializeRentData(item.data),
                      }
                    }
                  )
                  this.addEdit.vehicleModels = res.data

                  this.addEdit.primaryKey = primaryKey
                })
                .catch((err) => {
                  console.log('rent-plan-vehicle-model-err', err.response)
                  this.$notify({
                    group: 'bottomRight',
                    type: 'error',
                    title: `Server Error`,
                    text: 'Failed to retrieve data. Please try again.',
                  })
                })
            })
          )
          .catch((err) => {
            console.warn({ err })

            this.$edgeStack.close(this.addEdit.esId)
            this.$notify({
              group: 'bottomRight',
              type: 'error',
              title: `Server Error`,
              text: 'Failed to retrieve data. Please try again.',
            })
          })

        return
      }

      this.addEdit.stepperMode = 'strict'

      this.addEdit.busy = false
      this.addEdit.rawData = null // for rent plan
      this.addEdit.vehicleModels = null // for rent plan
      this.addEdit.rentPackages = null // for rent plan
      this.addEdit.primaryKey = null // for rent plan

      this.$edgeStack.open(this.addEdit.esId)
      // this.$xStepper.navigate(this.addEdit.esId).to(4)
    },
    getCurrencySymbol(item) {
      // console.log(item.fleet.country.currency_symbol)
      return item?.fleet?.country?.currency_symbol || this.symbol || '$'
    },
  },
}
</script>

<style></style>
